import { Link } from 'gatsby';
import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import './Pagination.scss';

const Pagination: React.FC<{
  basePath: string;
  currentPage: number;
  totalPages: number;
}> = ({ basePath, currentPage, totalPages }) => {
  if (totalPages === 1) return null;

  return (
    <div className="pagination">
      {currentPage > 1 && (
        <Link
          className="pagination-prev"
          to={currentPage === 2 ? basePath : `${basePath}${currentPage - 1}/`}
        >
          <FaChevronLeft />
          Anterior
        </Link>
      )}

      {Array.from(Array(totalPages).keys()).map((index) => (
        // eslint-disable-next-line react/self-closing-comp
        <Link
          key={index}
          className={index + 1 === currentPage ? 'pagination-num pagination-active' : 'pagination-num'}
          to={index === 0 ? basePath : `${basePath}${index + 1}/`}
        >
          {index + 1}
        </Link>
      ))}

      {currentPage < totalPages && (
        <Link
          className="pagination-next"
          to={`${basePath}${currentPage + 1}/`}
        >
          Próxima
          <FaChevronRight />
        </Link>
      )}
    </div>
  );
};

export default Pagination;
