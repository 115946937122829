import { Link, PageProps } from 'gatsby';
import React from 'react';
import { FaChevronDown, FaMapMarkerAlt } from 'react-icons/fa';

import ExperienceList from '../../components/ExperienceList';
import Layout from '../../components/Layout';
import Pagination from '../../components/Pagination';
import PrismicRichText from '../../components/PrismicRichText';
import { Category as CategoryType } from '../../types/category';
import { ExperiencePreview, LocationsInExperiences } from '../../types/experience';
import { Subcategory } from '../../types/subcategory';

import './Category.scss';

export interface CategoryPageContext {
  category: CategoryType;
  subcategories: Subcategory[];
  activeSubcategory?: Subcategory;
  locations: [LocationsInExperiences, LocationsInExperiences];
  activeLocation?: string;
  experiences: ExperiencePreview[];
  currentPage: number;
  totalPages: number;
}

// eslint-disable-next-line max-len
const Category: React.FC<PageProps<unknown, CategoryPageContext>> = ({ pageContext: { category, subcategories, activeSubcategory, locations, activeLocation, experiences, currentPage, totalPages }, path }) => (
  <Layout
    mainClassName="category"
    title={[
      activeSubcategory?.title ?? activeSubcategory?.name ?? category.name,
      activeLocation,
      'Experiências Incríveis',
    ].filter((part) => part).join(' - ')}
    meta={{
      description: activeSubcategory?.description ?? category.description,
      image: category.previewPhoto,
    }}
  >
    <div className="category-heading">
      <div className="container">
        <h1>{(activeSubcategory?.title ?? activeSubcategory?.name) ?? category.name}</h1>
        {activeSubcategory ? <p>{activeSubcategory.description}</p> : <PrismicRichText content={category.description} />}
      </div>
    </div>

    {(subcategories.length > 0 || locations.length > 0) && (
      <div className="container category-filters">
        {subcategories.length > 0 && (
          <div className="category-filters-sub">
            <ul>
              <li>
                <Link
                  to={category.path}
                  className={!activeSubcategory ? 'filter-active' : undefined}
                >
                  Tudo
                </Link>
              </li>

              {subcategories.map((subcategory) => (
                <li key={subcategory.path}>
                  <Link
                    to={subcategory.path}
                    className={subcategory.path === activeSubcategory?.path ? 'filter-active' : undefined}
                  >
                    {subcategory.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        {(locations[0].length > 0 || locations[1].length > 0) && (
          <div className="category-filters-location">
            <h4>
              <FaMapMarkerAlt /> Em: <strong>{activeLocation ?? 'Qualquer Lugar'}</strong>
              <FaChevronDown />
            </h4>

            <div>
              {activeLocation && (
                <ul>
                  <li>
                    <Link to={activeSubcategory?.path ?? category.path}>
                      Qualquer Lugar
                    </Link>
                  </li>
                </ul>
              )}

              {locations[0].length > 0 && (
                <>
                  <h5>No Brasil:</h5>

                  <ul>
                    {locations[0].map((location) => (
                      <li key={location.slug}>
                        <Link to={`${activeSubcategory?.path ?? category.path}${location.slug}/`}>
                          {location.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}

              {locations[1].length > 0 && (
                <>
                  <h5>Mundo Afora:</h5>

                  <ul>
                    {locations[1].map((location) => (
                      <li key={location.slug}>
                        <Link to={`${activeSubcategory?.path ?? category.path}${location.slug}/`}>
                          {location.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        )}
      </div>
    )}

    <div className="container">
      <ExperienceList experiences={experiences} />

      <Pagination
        basePath={path.replace(/\/[0-9]+\/$/, '/')}
        currentPage={currentPage}
        totalPages={totalPages}
      />
    </div>
  </Layout>
);

export default Category;
